@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
*{
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 4s infinite;
}
.text-shadow-glow {
  text-shadow: 
  0 0 10px rgba(0, 0, 0, 0.9), 
  0 0 20px rgba(0, 0, 0, 0.8), 
  0 0 30px rgba(0, 0, 0, 0.7), 
  0 0 40px rgba(0, 0, 0, 0.6), 
  0 0 50px rgba(0, 0, 0, 0.5);
}